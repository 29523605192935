import api from '../utils/api';

// Actions
const FETCH = 'COURSES/SOLUTIONS/FETCH';
const RECEIVE = 'COURSES/SOLUTIONS/RECEIVE';

// Initial state for reducer
const initialState = {
    solutionsLoading: true,
    solutions: [],
};

// Reducer(s)
export default function solutionsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH:
            return {
                ...state,
                solutionsLoading: true,
            };
        case RECEIVE:
            return {
                ...state,
                solutionsLoading: false,
                solutions: action.solutions,
            };
        default:
            return state;
    }
}

// Action creators
const fetch = () => ({type: FETCH});
const receive = solutions => ({type: RECEIVE, solutions});

let solutionsTimeout = null;

export const refreshSolutions = (workspaceID, userID, challengeID) => (dispatch) => {
    dispatch(fetch());
    api.coursesSolutionList.fetch(null, {workspace: workspaceID, user: userID, challenge: challengeID}).then(
        (data) => {
            dispatch(receive(data.results));
            clearTimeout(solutionsTimeout);
            solutionsTimeout = setTimeout(() => dispatch(refreshSolutions(workspaceID, userID, challengeID)), 10000);
        },
        () => dispatch(receive(null)),
    );
};
