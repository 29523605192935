import React from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';

import {gettext} from 'utils/text';
import {overviewColType} from 'propTypes/courses';

const OverviewTable = ({cols, rows}) => {
    const columns = cols.map((col) => {
        const res = {
            Header: col.header,
            accessor: col.accessor,
            className: 'pl-3',
            nrOfChallenges: col.nr_of_challenges,
            width: 180,
        };

        if (col.accessor === 'name') {
            Object.assign(res, {
                sortMethod: (a, b) => a.user_name.localeCompare(b.user_name, {sensitivity: 'base'}),
                Cell: row => <a href={row.value.profile_link}>{row.value.user_name}</a>,
            });
        } else {
            Object.assign(res, {
                Cell: row => <div>{row.value || 0} / {col.nr_of_challenges}</div>,
            });
        }

        return res;
    });

    /**
     * Cell background colors from white to green based on percentage.
     */
    const percentageToStyle = (percentage) => {
        const baseStyle = {
            backgroundColor: '#F8F9FA',
            color: '#212428',
        };

        if (percentage <= 0) {
            return baseStyle;
        } else if (percentage <= 20) {
            return {
                backgroundColor: '#E3F7E5',
                color: '#2B562B',
            };
        } else if (percentage <= 40) {
            return {
                backgroundColor: '#DBEFDD',
                color: '#2B562B',
            };
        } else if (percentage <= 60) {
            return {
                backgroundColor: '#D6E7D7',
                color: '#2B562B',
            };
        } else if (percentage <= 80) {
            return {
                backgroundColor: '#D0E1D1',
                color: '#2B562B',
            };
        } else if (percentage < 100) {
            return {
                backgroundColor: '#C8DBCA',
                color: '#2B562B',
            };
        } else if (percentage === 100) {
            return {
                backgroundColor: '#C2D5C4',
                color: '#2B562B',
            };
        }
        return baseStyle;
    };

    const getTdProps = (state, rowInfo, column) => {
        const {nrOfChallenges} = column;
        if (!rowInfo || nrOfChallenges < 0) {
            return {};
        }

        const cellValue = rowInfo.row[column.id] || 0;
        const percentage = (nrOfChallenges === 0) ? 100 : (cellValue / nrOfChallenges) * 100;

        return {
            className: 'text-center',
            style: percentageToStyle(percentage),
        };
    };

    return (
        <ReactTable
            data={rows}
            columns={columns}
            defaultPageSize={10}
            defaultSorted={[{id: 'name', asc: true}]}
            showPageSizeOptions={false}
            previousText={gettext('Previous')}
            nextText={gettext('Next')}
            loadingText={gettext('Loading')}
            noDataText={gettext('No rows found')}
            rowsText={gettext('rows')}
            pageText={gettext('Page')}
            ofText={gettext('of')}
            getTdProps={getTdProps}
        />
    );
};

OverviewTable.propTypes = {
    cols: PropTypes.arrayOf(overviewColType).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.shape({
            profile_link: PropTypes.string.isRequired,
            user_name: PropTypes.string.isRequired,
        }),
    })).isRequired,
};

const CourseOverview = ({overviewData}) => (
    <div>
        <OverviewTable cols={overviewData.columns} rows={overviewData.rows} />
        <div className="text-muted text-center mt-3">
            {gettext("Hold shift when sorting to multi-sort.")}
        </div>
    </div>
);

CourseOverview.propTypes = {
    overviewData: PropTypes.shape({
        columns: PropTypes.arrayOf(overviewColType).isRequired,
        rows: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.shape({
                profile_link: PropTypes.string.isRequired,
                user_name: PropTypes.string.isRequired,
            }),
        })),
    }).isRequired,
};

export default CourseOverview;
