import PropTypes from 'prop-types';
import {userType} from 'propTypes/accounts';

const solutionTypeShape = {
    context_class: PropTypes.string.isRequired,
    created_timestamp: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    solution: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
};

export const solutionType = PropTypes.shape(solutionTypeShape);

export const overviewColType = PropTypes.shape({
    header: PropTypes.string,
    accessor: PropTypes.string.isRequired,
    nr_of_challenges: PropTypes.number,
});

export const challengeType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    golf: PropTypes.bool.isRequired,
});

export const solutionExtendedType = PropTypes.shape({
    ...solutionTypeShape,
    challenge: challengeType.isRequired,
    user: userType.isRequired,
});
