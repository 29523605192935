const toggleClass = (el, className) => {
    if (el.classList) {
        el.classList.toggle(className);
    } else {
        const classes = el.className.split(' ');
        const existingIndex = classes.indexOf(className);

        if (existingIndex >= 0) {
            classes.splice(existingIndex, 1);
        } else {
            classes.push(className);
        }

        el.className = classes.join(' '); // eslint-disable-line no-param-reassign
    }
};


export {toggleClass}; // eslint-disable-line import/prefer-default-export
