import PropTypes from 'prop-types';
import React from 'react';
import AsyncSelect from 'react-select/lib/Async';

import api from 'utils/api';
import {gettext} from 'utils/text';


class WorkspaceMemberSelect extends React.Component {
    static propTypes = {
        excludedUserIDs: PropTypes.arrayOf(PropTypes.number).isRequired,
    };

    state = {
        value: '',
    };

    onChange(value) {
        this.setState({value});
    }

    loadOptions(inputValue) {
        return new Promise((resolve) => {
            const excludeIDs = this.props.excludedUserIDs.join(',');
            api.accountsUserList.fetch(null, {q: inputValue, exclude_ids: excludeIDs}).then((data) => {
                resolve(data.results.map(u => ({label: u.name, value: u.id})));
            });
        });
    }

    render() {
        return (
            <div>
                <input type="hidden" name="member" value={this.state.value.value || ''} />
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={val => this.loadOptions(val)}
                    onChange={val => this.onChange(val)}
                    placeholder={gettext('Select user...')}
                    loadingMessage={() => gettext('Loading...')}
                    noOptionsMessage={() => gettext('No matching results found')}
                />
            </div>
        );
    }
}

export default WorkspaceMemberSelect;
