import {toggleClass} from './dom';


const initNavbar = () => {
    const navbarTogglers = document.querySelectorAll('.navbar-toggler');
    for (let i = 0; i < navbarTogglers.length; i += 1) {
        const toggler = navbarTogglers[i];
        const target = toggler.getAttribute('data-target');
        if (target) {
            toggler.onclick = (e) => {
                e.preventDefault();
                const collapses = document.querySelectorAll(target);
                for (let j = 0; j < collapses.length; j += 1) {
                    const collapse = collapses[j];
                    toggleClass(collapse, 'show');
                }
            };
        }
    }
};


export default initNavbar;
