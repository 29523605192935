import api from '../utils/api';


// Actions
const LOAD = 'COURSES/CHALLENGES/LOAD';
const UPDATE = 'COURSES/CHALLENGES/UPDATE';

// Initial state for reducer
const initialState = {
    isLoading: true,
    challenge: null,
};

// Reducer(s)
export default function challengesReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case UPDATE:
            return {
                challenge: action.challenge,
                isLoading: false,
            };
        default:
            return state;
    }
}

// Action creators
const load = () => ({type: LOAD});
const update = challenge => ({type: UPDATE, challenge});

const PENDING_STATUSES = ['pending', 'checking'];
export const refreshChallenge = challengeID => (dispatch) => {
    dispatch(load());
    api.coursesChallengeDetail.fetch({pk: challengeID}).then((data) => {
        dispatch(update(data));
        const pendingSolutions = data.solutions.filter(s => PENDING_STATUSES.indexOf(s.status) >= 0);
        const timeout = pendingSolutions.length > 0 ? 3000 : 60000;
        setTimeout(() => dispatch(refreshChallenge(challengeID)), timeout);
    });
};
