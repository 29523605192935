import moment from 'moment';

function momentify() {
    const containers = document.querySelectorAll('.momentify');
    for (let i = 0; i < containers.length; i += 1) {
        const container = containers[i];
        const timestamp = container.getAttribute('data-timestamp');
        if (timestamp) {
            container.innerText = moment(timestamp).calendar();
        }
    }
}

export default momentify;
