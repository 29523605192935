import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import Raven from 'raven-js';
import {Provider} from 'react-redux';

import CourseManagementButtons from 'components/CourseManagementButtons';
import CourseOverview from 'components/CourseOverview';
import WorkspaceSolutions from 'components/WorkspaceSolutions';
import UserSolutions from 'components/UserSolutions';
import WorkspaceMemberSelect from 'components/WorkspaceMemberSelect';
import initLanguageSelect from 'utils/language';
import momentify from 'utils/momentify';
import rootReducer from './reducers';
import configureStore from './store';
import initNavbar from './utils/navbar';


// Install Raven in production envs
if (process.env.NODE_ENV === 'production') {
    Raven.config(DJ_CONST.RAVEN_PUBLIC_DSN).install(); // eslint-disable-line
    // handle rejected promises
    window.addEventListener('unhandledrejection', (evt) => {
        Raven.captureException(evt.reason);
    });
    // If we have authenticated user, pass its data on to Raven
    if (DJ_CONST.user) {
        Raven.setUserContext({
            id: DJ_CONST.user.id,
            email: DJ_CONST.user.email,
            name: DJ_CONST.user.name,
        });
    }
}

// Create Redux store
const store = configureStore(rootReducer);

function init() {
    moment.locale(DJ_CONST.lang_code);
    initNavbar();
    initLanguageSelect();
    momentify();
}

function initChallengeDetailView(challengeID, userSolutionsContainer, statuses) {
    const elem = document.getElementById(userSolutionsContainer);
    if (!elem) {
        return;
    }

    ReactDOM.render(
        <Provider store={store}>
            <UserSolutions challengeID={challengeID} statuses={statuses} />
        </Provider>,
        elem,
    );
}

function initCourseDetailPage(buttonContainer, workspaceSlug, courseId) {
    const elem = document.getElementById(buttonContainer);
    if (!elem) {
        return;
    }

    ReactDOM.render(
        <Provider store={store}>
            <CourseManagementButtons workspaceSlug={workspaceSlug} courseId={courseId} />
        </Provider>,
        elem,
    );
}

function initCourseOverviewView(overviewContainer, overviewData) {
    const elem = document.getElementById(overviewContainer);
    if (!elem) {
        return;
    }

    ReactDOM.render(
        <Provider store={store}>
            <CourseOverview overviewData={overviewData} />
        </Provider>,
        elem,
    );
}

function initWorkspaceDetailView(workpaceID, workspaceSlug, solutionsContainer, statuses) {
    const elem = document.getElementById(solutionsContainer);
    if (!elem) {
        return;
    }

    ReactDOM.render(
        <Provider store={store}>
            <WorkspaceSolutions workspaceID={workpaceID} workspaceSlug={workspaceSlug} statuses={statuses} />
        </Provider>,
        elem,
    );
}

function initWorkspaceMemberListView(selectContainer, workspaceMemberUserIDs) {
    const elem = document.getElementById(selectContainer);
    if (!elem) {
        return;
    }

    const excludedUserIDs = workspaceMemberUserIDs.split(',').map(uid => parseInt(uid, 10));
    ReactDOM.render(
        <Provider store={store}>
            <WorkspaceMemberSelect excludedUserIDs={excludedUserIDs} />
        </Provider>,
        elem,
    );
}

export {
    init, initChallengeDetailView, initCourseDetailPage, initCourseOverviewView, initWorkspaceDetailView,
    initWorkspaceMemberListView,
};
