import Router, {Resource} from 'tg-resources';

import {getCookie} from './cookie';


const api = new Router({
    // eslint-disable-next-line no-template-curly-in-string
    coursesChallengeDetail: new Resource(DJ_CONST.reverse['api:courses:challenge_detail']('${pk}')),
    coursesChallengeList: new Resource(DJ_CONST.reverse['api:courses:challenge_list']()),
    coursesSolutionList: new Resource(DJ_CONST.reverse['api:courses:solution_list']()),
    accountsUserList: new Resource(DJ_CONST.reverse['api:accounts:user_list']()),
}, {
    headers: () => ({
        Accept: 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
    }),
});

export default api;
