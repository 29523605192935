import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AsyncSelect from 'react-select/lib/Async';

import {refreshSolutions} from 'ducks/solutions';
import {solutionExtendedType} from 'propTypes/courses';
import api from 'utils/api';
import {gettext} from 'utils/text';


class WorkspaceSolutions extends React.Component {
    static propTypes = {
        workspaceID: PropTypes.number.isRequired,
        workspaceSlug: PropTypes.string.isRequired,

        refreshSolutions: PropTypes.func.isRequired,
        solutions: PropTypes.arrayOf(solutionExtendedType),
        solutionsLoading: PropTypes.bool.isRequired,

        statuses: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    };

    static defaultProps = {
        solutions: null,
    };

    state = {
        selectedUser: null,
        selectedChallenge: null,
    };

    componentDidMount() {
        this.props.refreshSolutions(this.props.workspaceID);
    }

    handleSelectedUserChange = (selectedUser) => {
        this.setState({selectedUser});
        this.props.refreshSolutions(
            this.props.workspaceID,
            selectedUser ? selectedUser.value : null,
            this.state.selectedChallenge ? this.state.selectedChallenge.value : null,
        );
    };

    handleSelectedChallengeChange = (selectedChallenge) => {
        this.setState({selectedChallenge});
        this.props.refreshSolutions(
            this.props.workspaceID,
            this.state.selectedUser ? this.state.selectedUser.value : null,
            selectedChallenge ? selectedChallenge.value : null,
        );
    };

    loadUserOptions = val => new Promise((resolve) => {
        api.accountsUserList.fetch(null, {q: val, workspace: this.props.workspaceID}).then((data) => {
            resolve(data.results.map(u => ({label: u.name, value: u.id})));
        });
    });

    loadChallengeOptions = val => new Promise((resolve) => {
        api.coursesChallengeList.fetch(null, {q: val, workspace: this.props.workspaceID}).then((data) => {
            resolve(data.results.map(c => ({label: c.title, value: c.id})));
        });
    });

    renderTable() {
        if (this.props.solutionsLoading && (!this.props.solutions || !this.props.solutions.length)) {
            return (<p className="lead text-center mt-4">{gettext('Loading solutions..')}</p>);
        }

        if (this.props.solutions === null) {
            return (<p className="lead text-center mt-4">{gettext('There was an error loading solutions :(')}</p>);
        }

        if (this.props.solutions.length === 0) {
            return (<div className="lead text-center mt-4">{gettext('No solutions submitted yet :(')}</div>);
        }

        const {statuses, workspaceSlug} = this.props;
        const challengeDetailURL = DJ_CONST.reverse['courses:challenge-detail'];
        const solutionDetailURL = DJ_CONST.reverse['courses:solution-detail'];

        return (
            <div className="table-responsive-md mt-4">
                <table className="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th scope="col" className="border-top-0">{gettext('ID')}</th>
                            <th scope="col" className="border-top-0">{gettext('Submitted')}</th>
                            <th scope="col" className="border-top-0">{gettext('User')}</th>
                            <th scope="col" className="border-top-0">{gettext('Challenge')}</th>
                            <th scope="col" className="border-top-0">{gettext('Filename')}</th>
                            <th scope="col" className="border-top-0">{gettext('Status')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.solutions.map(s => (
                            <tr key={s.id}>
                                <td><a href={solutionDetailURL(workspaceSlug, s.id)}>{s.id}</a></td>
                                <td>{moment(s.created_timestamp).calendar()}</td>
                                <td>{s.user.name}</td>
                                <td>
                                    <a href={challengeDetailURL(workspaceSlug, s.challenge.id)}>
                                        {s.challenge.title}
                                    </a>
                                </td>
                                <td>
                                    <a href={s.solution}>{s.filename}</a>
                                </td>
                                <td>
                                    <span className={`badge badge-${s.context_class}`}>{statuses[s.status]}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        return (
            <div>
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    isClearable
                    loadOptions={val => this.loadUserOptions(val)}
                    onChange={this.handleSelectedUserChange}
                    placeholder={gettext('Filter by user')}
                    loadingMessage={() => gettext('Loading...')}
                    noOptionsMessage={() => gettext('No matching results found')}
                />
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    isClearable
                    loadOptions={val => this.loadChallengeOptions(val)}
                    onChange={this.handleSelectedChallengeChange}
                    placeholder={gettext('Filter by challenge')}
                    loadingMessage={() => gettext('Loading...')}
                    noOptionsMessage={() => gettext('No matching results found')}
                    className="mt-4"
                />
                {this.renderTable()}
            </div>
        );
    }
}

const mapStateToProps = state => ({...state.solutions});

const mapDispatchToProps = dispatch => ({
    refreshSolutions: (workspace, user, challenge) => dispatch(refreshSolutions(workspace, user, challenge)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WorkspaceSolutions);
