function initLanguageSelect() {
    const languageForm = document.getElementById('change-lang-form');
    const languageInput = document.getElementById('change-lang-input');
    if (!languageForm || !languageInput) {
        return;
    }
    const languageLinks = document.querySelectorAll(`[data-lang-code]`);
    for (let i = 0; i < languageLinks.length; i += 1) {
        const languageLink = languageLinks[i];
        languageLink.onclick = (e) => {
            e.preventDefault();
            languageInput.value = languageLink.getAttribute('data-lang-code');
            languageForm.submit();
        };
    }
}

export default initLanguageSelect;
