import React from 'react';
import {ButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu} from 'reactstrap';
import PropTypes from 'prop-types';

import {gettext} from 'utils/text';


class CourseManagementButtons extends React.Component {
    static propTypes = {
        workspaceSlug: PropTypes.string.isRequired,
        courseId: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    render() {
        const {workspaceSlug, courseId} = this.props;
        return (
            <ButtonDropdown
                isOpen={this.state.isOpen}
                toggle={() => this.toggle()}
                size="sm"
            >
                <a
                    href={DJ_CONST.reverse['courses:lesson-create'](workspaceSlug, courseId)}
                    className="btn btn-warning"
                    color="warning"
                >
                    <i className="far fa-plus-square" />
                    <span> {gettext("Add lesson")}</span>
                </a>
                <DropdownToggle caret color="warning" />
                <DropdownMenu right>
                    <DropdownItem href={DJ_CONST.reverse['courses:course-overview'](workspaceSlug, courseId)}>
                        <i className="far fa-clipboard" />
                        <span> {gettext("Overview")}</span>
                    </DropdownItem>
                    <DropdownItem href={DJ_CONST.reverse['courses:course-statistics'](workspaceSlug, courseId)}>
                        <i className="far fa-chart-bar" />
                        <span> {gettext("Statistics")}</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href={DJ_CONST.reverse['courses:course-edit'](workspaceSlug, courseId)}>
                        <i className="far fa-edit" />
                        <span> {gettext("Edit")}</span>
                    </DropdownItem>
                    <DropdownItem href={DJ_CONST.reverse['courses:course-password'](workspaceSlug, courseId)}>
                        <i className="fas fa-key" />
                        <span> {gettext("Password")}</span>
                    </DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
        );
    }
}

export default CourseManagementButtons;
