import {combineReducers} from 'redux';

import challenges from './ducks/challenges';
import solutions from './ducks/solutions';

const rootReducer = combineReducers({
    challenges,
    solutions,
});

export default rootReducer;
