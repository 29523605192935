import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';

import {refreshChallenge} from 'ducks/challenges';
import {userType} from 'propTypes/accounts';
import {solutionType} from 'propTypes/courses';
import {gettext} from 'utils/text';

const OTHERS_TAB = 'others';
const USER_TAB = 'user';


class UserSolutions extends React.Component {
    static propTypes = {
        challenge: PropTypes.shape({
            id: PropTypes.number.isRequired,
            passed_users: PropTypes.arrayOf(PropTypes.shape({
                user: userType,
                solution_size: PropTypes.number.isRequired,
                points: PropTypes.number,
            })).isRequired,
            solutions: PropTypes.arrayOf(solutionType).isRequired,
            title: PropTypes.string.isRequired,
            golf: PropTypes.bool.isRequired,
        }),
        challengeID: PropTypes.number.isRequired,
        isLoading: PropTypes.bool.isRequired,
        refreshChallenge: PropTypes.func.isRequired,
        statuses: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    };

    static defaultProps = {
        challenge: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            activeTab: USER_TAB,
            activeSolution: null,
        };
    }

    componentDidMount() {
        this.props.refreshChallenge(this.props.challengeID);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    toggleSolution(solution) {
        const open = this.state.activeSolution !== solution;
        this.setState({
            activeSolution: open ? solution : null,
        });
    }

    truncateChars = (string, length) => {
        const dots = string.length > length ? '...' : '';
        return string.substring(0, length) + dots;
    };

    renderInfo = content => (
        <div className="p-5 text-center text-secondary lead">{content}</div>
    );

    renderPassedUsers(passedUsers) {
        if (passedUsers.length === 0) {
            return this.renderInfo(gettext('No correct solutions yet :('));
        }
        const isGolf = this.props.challenge.golf;

        return (
            <div className="table-responsive-md">
                <table className="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th scope="col" className="border-top-0 pl-3">#</th>
                            <th scope="col" className="border-top-0">{gettext('Name')}</th>
                            {isGolf && [
                                <th key="bytes" scope="col" className="border-top-0">{gettext('Bytes')}</th>,
                                <th key="points" scope="col" className="border-top-0">{gettext('Points')}</th>,
                            ]}
                        </tr>
                    </thead>
                    <tbody>
                        {passedUsers.map((pu, i) => (
                            <tr key={pu.user.id}>
                                <th scope="row" className="pl-3">{i + 1}</th>
                                <td>{this.truncateChars(pu.user.name, 50)}</td>
                                {isGolf && [
                                    <td key={`${pu.user.id}-size`}>{pu.solution_size}</td>,
                                    <td key={`${pu.user.id}-points`}>{pu.points}</td>,
                                ]}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    renderFeedback = (test) => {
        const isGolf = this.props.challenge.golf;
        const time = Math.round(test.seconds * 100) / 100;

        let label = 'success';
        if (test.flavor === 'FAIL') {
            label = 'danger';
        } else if (test.flavor === 'ERROR') {
            label = 'warning';
        }
        return (
            <tr key={test.test} className="table-accent">
                <td className="pl-5 text-muted" colSpan={1 + !!isGolf}>{test.test}</td>
                <td>
                    <span className={`badge badge-${label}`}>{test.flavor}</span>
                </td>
                <td className="text-right">{time} {gettext('seconds')}</td>
            </tr>
        );
    };

    renderSolution = (solution) => {
        const {statuses} = this.props;
        const isGolf = this.props.challenge.golf;
        const isActiveSolution = solution.id === this.state.activeSolution;
        const hasFeedback = solution.feedback !== null;
        const solutionElem = (
            <tr
                key={solution.id}
                onClick={() => solution.feedback !== null && this.toggleSolution(solution.id)}
                className={classnames({"table-info": isActiveSolution}, {"row-pointer": hasFeedback})}
            >
                <th scope="row" className="pl-3"><a href={solution.solution}>{solution.filename}</a></th>
                {isGolf && <td>{solution.solution_size}</td>}
                <td>
                    <span className={`badge badge-${solution.context_class}`}>{statuses[solution.status]}</span>
                </td>
                <td className="text-right">{moment(solution.created_timestamp).calendar()}</td>
                <td className="text-center">{hasFeedback ? <i className="fa fa-info-circle" /> : null}</td>
            </tr>
        );

        if (isActiveSolution && hasFeedback) {
            return [solutionElem, solution.feedback.tests.map(this.renderFeedback)];
        }
        return solutionElem;
    };

    renderUserSolutions(solutions) {
        if (solutions.length === 0) {
            return this.renderInfo(gettext('No solutions submitted yet :('));
        }
        const isGolf = this.props.challenge.golf;

        return (
            <div className="table-responsive-md">
                <table className="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th scope="col" className="border-top-0 pl-3">{gettext('Filename')}</th>
                            {isGolf && <th scope="col" className="border-top-0">{gettext('Bytes')}</th>}
                            <th scope="col" className="border-top-0">{gettext('Status')}</th>
                            <th scope="col" className="border-top-0 text-right">{gettext('Submitted')}</th>
                            <th scope="col" className="border-top-0 text-center">Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        {solutions.map(this.renderSolution)}
                    </tbody>
                </table>
            </div>
        );
    }

    renderOthersTab() {
        const errorMessage = gettext('There was an error loading other users :/');

        if (this.props.isLoading && this.props.challenge === null) {
            return this.renderInfo(<i className="fa fa-spinner fa-spin fa-3x" />);
        } else if (this.props.challenge === null) {
            return this.renderInfo(errorMessage);
        } else {
            return this.renderPassedUsers(this.props.challenge.passed_users);
        }
    }

    renderUserTab() {
        const errorMessage = gettext('There was an error loading your solutions :/');

        if (this.props.isLoading && this.props.challenge === null) {
            return this.renderInfo(<i className="fa fa-spinner fa-spin fa-3x" />);
        } else if (this.props.challenge === null) {
            return this.renderInfo(errorMessage);
        } else {
            return this.renderUserSolutions(this.props.challenge.solutions);
        }
    }

    render() {
        return (
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            href="#"
                            className={classnames({active: this.state.activeTab === USER_TAB})}
                            onClick={() => this.toggleTab(USER_TAB)}
                        >
                            {gettext('Your solutions')}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            href="#"
                            className={classnames({active: this.state.activeTab === OTHERS_TAB})}
                            onClick={() => this.toggleTab(OTHERS_TAB)}
                        >
                            {gettext('Other correct solutions')}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={USER_TAB}>
                        {this.renderUserTab()}
                    </TabPane>
                    <TabPane tabId={OTHERS_TAB}>
                        {this.renderOthersTab()}
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}

const mapStateToProps = state => ({...state.challenges});

const mapDispatchToProps = dispatch => ({
    refreshChallenge: challengeID => dispatch(refreshChallenge(challengeID)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserSolutions);
